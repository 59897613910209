import {useRef} from "react";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {borderRadius, p} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";

import {Checkbox, ICheckboxProps} from "./Checkbox";

type IButtonCheckboxProps = ICheckboxProps & {
    isLoading?: boolean;
};

export function CheckboxButton(props: IButtonCheckboxProps) {
    const theme = useTheme();
    const {isLoading, id = props.name, checkedMarkColor = theme.globals.checkbox.checkedColor, ...restProps} = props;
    const checkboxRef = useRef<HTMLInputElement | null>(null);

    return (
        <CheckboxWrap
            isSelected={!!props.checked}
            onClick={(e) => {
                if (!props.readOnly && (e.target as HTMLElement).nodeName !== "INPUT" && (e.target as HTMLElement).nodeName !== "LABEL") {
                    props.onChange(props.name, !props.checked, props.value as HTMLInputElement["value"]);
                }
            }}
        >
            <Checkbox ref={checkboxRef} css={checkboxStyle} id={id} checkedMarkColor={checkedMarkColor} {...restProps} />
            {isLoading && (
                <div css={loaderStyle}>
                    <Loader size="sm" />
                </div>
            )}
        </CheckboxWrap>
    );
}

const CheckboxWrap = styled.div<{isSelected: boolean}>`
    ${({theme, isSelected}) => css`
        background: ${theme.colors.gray[100]};
        ${p(1)};
        ${borderRadius(1)};
        position: relative;
        cursor: pointer;
        border: 1px solid transparent;
        display: flex;

        ${isSelected &&
        css`
            border: 1px solid ${theme.colors.gray[500]};
        `}
    `}
`;

const checkboxStyle = css`
    user-select: none;
    display: flex;
    position: relative;
    z-index: 1;
`;

const loaderStyle = css`
    position: absolute;
    z-index: 2;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    ${borderRadius(1)};
`;
